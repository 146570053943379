@import "colors", "fonts";

nav {
  height: calc(100vh - 5em);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr max-content;
  box-sizing: border-box;
}

.container-circle-recast {
  padding: 1em;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  grid-column-gap: 1em;
  box-sizing: border-box;
  p {
    font-weight: 800;
    font-size: $font_normal;
  }
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
  li {
    padding: 0 20px;
    width: 100%;
    height: 3.2em;
    min-height: max-content;
    display: grid;
    grid-template-columns: 1fr 40px;
    grid-template-rows: 1fr;
    grid-column-gap: 15px;
    align-items: center;
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
    color: black;
  }
}

.li-profile {
  height: 5em;
  display: grid;
  grid-template-columns: 45px 1fr 23px;
  grid-template-rows: 1fr;
  grid-column-gap: 1em;
  padding: 1em;
  box-sizing: border-box;
  cursor: auto;
  div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
}

.smallNavLeft {
  ul {
    width: max-content;
    .li-profile {
      grid-template-columns: 1fr;
      width: 70px;
      padding: 0;
      div {
        display: none;
      }
      .img-profile {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .container-circle-recast {
    grid-template-columns: 1fr;
    p {
      display: none;
    }
  }
}

.img-profile {
  img {
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }
}

.user-inf {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  grid-row-gap: 0.4em;
  align-items: center;
  p {
    margin: 0;
    font-size: $font_small;
    font-family: $secondary_font;
    font-weight: 800;
  }
}

.enterprise-name {
  font-size: $font_very_small;
  color: $secondary_text_color;
}

li {
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-family: $primary_font;
  font-weight: 400;
  font-size: $font_normal;
}

.normal-li,
.selected-li {
  &:hover {
    background-color: $hover_background;
    color: $hover_color;
    fill: $hover_color;
  }
}

.selected-li {
  color: $selected_li;
  fill: $selected_li;
  background-color: $background_body;
  border-right: solid 4px $h1_primary_color;
}

.smallNavLeft-li {
  grid-template-columns: 1fr;
  width: 70px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    display: none;
  }
}
