@import "colors", "fonts";

header {
  width: 100vw;
  height: 5em;
  background-color: $primary_background_color;
  color: $secondary_text_color;
  border-bottom: solid 1px $border_color;
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  font-family: $primary_font;
  border-bottom: solid 3px;
  border-image: $border_image_primary;
  box-sizing: border-box;
}

.smallNav {
  grid-template-columns: 70px 1fr;
  .header-first-column {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}

.header-first-column {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  color: $h1_primary_color;
  fill: $h1_primary_color;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  align-items: center;
  box-sizing: border-box;
  h1 {
    font-size: $font_big;
    letter-spacing: 1px;
    font-weight: 900;
    margin: 0;
  }
}

.header-second-column {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  display: grid;
  grid-template-columns: 1fr max-content max-content;
  grid-template-rows: 1fr;
  grid-column-gap: 35px;
  align-items: center;
  box-sizing: border-box;
  button {
    width: max-content;
    height: max-content;
    background-color: transparent;
    border: none;
    cursor: pointer;
    fill: $secondary_text_color;
    box-sizing: border-box;
    & :hover {
      fill: $primary_text_color;
    }
  }
}

.user-information {
  width: max-content;
  height: 100%;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 1fr;
  grid-column-gap: 15px;
  align-items: center;
  box-sizing: border-box;
}

.img-container {
  width: 38px;
  height: 38px;
  box-sizing: border-box;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: 100%;
    z-index: -1;
  }
}

.user-status {
  width: 9px;
  height: 9px;
  background-color: $avaible_status_color;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: -3px;
  border-radius: 100%;
}

.user-data {
  width: max-content;
  height: max-content;
  max-height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  grid-row-gap: 4px;
  align-items: center;
  box-sizing: border-box;
  p {
    margin: 0;
  }
}

.user-data-email {
  font-size: $font_small;
}

.user-data-name {
  color: $primary_text_color;
}

.hidden {
  font-size: 0.1px;
  color: transparent;
}
