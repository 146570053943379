@import "fonts", "colors";

$weekly_refresh_grid: 180px 130px 180px 180px 330px 1fr;
$execution_grid: 200px 180px 120px 180px 180px 330px 1fr;
$job_size_grid: 180px 230px 280px 280px 230px 1fr;

.container-table-jobs {
  width: max-content;
}

.p-table-msg {
  width: calc(100% - 1em);
  box-sizing: border-box;
  background-color: $msg_table;
  font-weight: 800;
  font-size: $font_small;
  padding: 0.7em;
  margin-left: 1em;
}

.ingestContainer {
  margin-left: 1em;
  padding-left: 2em;
  column-width: 300px;
  column-gap: 20px;
  background-color: #eaece5;
}

.ingestCard {
  padding-top: 1px;
  padding-bottom: 5px;
  max-width: 300px;
  break-inside: avoid;
  h3 {
    margin: 0.25em 0 0.25em 0;
  }
  p {
    margin: 0.5em 0 0.5em 0;
  }
  pre {
    margin: 0.25em 0 0.25em 0;
  }
}

.depvarContainer {
  padding-left: 2em;
  column-width: 250px;
  column-gap: 20px;
  background-color: #eaece5;
}

.depvarCard {
  padding: 1px;
  margin-bottom: 1em;
  width: 200px;
  break-inside: avoid;
  h3 {
    margin: 0.25em 0 0.25em 0;
  }
  p {
    margin: 0.5em 0 0.5em 0;
  }
}

.tableLoad {
  display: grid;
  grid-template-columns: repeat(6, minmax(max-content, 1fr));
  grid-template-rows: auto;
  width: 100%;
  height: max-content;
  background-color: $table_load_background;
  margin-top: 1em;
  box-sizing: border-box;
  border: solid 1px $border_color;
  div {
    height: 47px;
    padding: 1em;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }
  p {
    height: 10px;
    width: 100%;
    background: $border_image_loader;
    background-size: 500px 100px;
    animation-name: moving-gradient;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  @keyframes moving-gradient {
    0% {
      background-position: -250px 0;
    }
    100% {
      background-position: 250px 0;
    }
  }
}

.tableJobs {
  display: grid;
  grid-template-columns:
    260px 160px 160px 160px 180px 180px 400px 300px 1fr;
  &.header-execution{
    grid-template-columns: $execution_grid;
    background-color: #faf8f4;
  }
  &.header-weekly-refresh{
    grid-template-columns: $weekly_refresh_grid;
    background-color: #faf8f4;
  }
  &.header-client-job-size{
    grid-template-columns: $job_size_grid;
    background-color: #faf8f4;
  }
  grid-template-rows: auto;
  min-width: 100%;
  height: max-content;
  font-family: $secondary_font;
  border: solid 1px $border_color;
  margin-top: 1em;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    border: solid 1px $border_bottom_table;
  }
  .tbody {
    background-color: $job_background_color;
    &.run-type {
      &:hover {
        background-color: $fleet_background_color;
      }
    }
  }
}

.tableFleets {
  display: grid;
  grid-template-columns: minmax(560px, 1fr) minmax(157px, 1fr) repeat(6, minmax(105px, 1fr)) minmax(360px, 1fr);

  &.header-task{
    grid-template-columns:200px 120px 180px 180px 100px 320px 1fr;
    background-color: #eaece5;
  }
  &.weekly-refresh-task{
    grid-template-columns:320px 120px 180px 180px 100px 320px;
    background-color: #eaece5;
  }

  grid-template-rows: auto;
  min-width: 100%;
  height: max-content;
  font-family: $secondary_font;
  border: solid 1px $border_color;
  margin-top: 1em;
  box-sizing: border-box;
  .thead,
  .tbody {
    background-color: $fleet_background_color;
    &.primary-task{
      font-weight: bold;
      p {
        margin: 0.5em 0 0.5em 0;
      }
    }
    &.depvar-task{
      font-weight: bold;
      p {
        margin: 0.5em 0 0.5em 0;
        font-size: 1.2em;
      }
    }
  }
}

.thead {
  padding: 1em;
  border-bottom: solid 1px $border_bottom_table;
  display: none;
  h1 {
    font-size: $font_small !important;
    margin: 0 !important;
    font-weight: 900;
  }
}

.tbody {
  height: 4em;
  padding: 0 1em;
  font-size: $font_normal_small;
  display: flex;
  align-items: center;
}

.status {
  width: max-content;
  height: max-content;
  background-color: #ac75ff;
  padding: 0.6em;
  font-weight: 800;
  color: $primary_background_color;
  text-transform: uppercase;
  border-radius: 4px;
  p {
    margin: 0;
    font-size: $font_very_small;
    letter-spacing: 1px;
  }
}

.succeeded {
  background-color: $succeeded_color !important;
}

.running {
  background-color: $running_color !important;
}

.pending {
  background-color: $pending_color !important;
}

.failed {
  background-color: $failed_color !important;
}

.completed {
  background-color: $completed_color !important;
}

.aborted {
  background-color: $aborted_color !important;
}

.container-fleets {
  margin-left: 1em;
}

.t-link {
  padding-left: 0;
  padding-right: 0;
}

.tbody-links {
  width: max-content;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5em;
  box-sizing: border-box;
  svg {
    height: 2em;
  }
  span,
  button {
    text-decoration: none;
    color: $contrast_text_color;
    width: max-content;
    height: 2em;
    background-color: $primary_text_color;
    border-radius: 3px;
    display: flex;
    align-items: center;
    padding: 0 0.7em;
    font-weight: 800;
    font-size: $font_normal_small;
    box-sizing: border-box;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: 600;
  }
  .type-true {
    background-color: #66d;
  }
  .type-false {
    background-color: #d84;
  }
  .bg-blue-btn {
    background-color: $button_background;
  }
  .bg-green-btn {
    background-color: $succeeded_color;
  }
  .button-link-cont {
    justify-self: flex-end;
    box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
    &.delete{
      background-color: $error_color !important;
    }
  }
}

.tbody-detail {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 1rem;
  &.actions{
    justify-content: flex-start;
  }
}

.delete-btn {
  background-color: $error_color !important;
  width: 5.3rem !important;
}

.modal-delete-container{
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  background-color: $backgound_modal;
  box-shadow: 2px 2px 2px 1px rgb(191 186 186 / 20%);
  box-sizing: border-box;
  .modal-dialog-confirm{
    width: 40rem;
    height: max-content;
    background-color: $primary_background_color;
    border-radius: 10px;
    padding: 2rem;
    box-sizing: border-box;
    font-family: $primary_font;
  }
  .modal-dialog-confirm-text{
    width: 100%;
    border: none;
    outline: none;
    border-bottom: solid 1px $secondary_text_color;
    padding: 6px;
    margin-top: 10px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  .container-buttons-md{
    width: 100%;
    height: max-content;
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    box-sizing: border-box;
    button{
      height: 2rem;
      width: max-content;
      padding: 0 1rem;
      margin-left: 1rem;
      cursor: pointer;
      border-radius: 6px;
      border: solid 1px $secondary_text_color;
    }
    .confirm-md-disabled{
      cursor: auto;
    }
    .confirm-md-enabled{
      background-color: $button_background;
      color: $primary_background_color;
      border: solid 1px transparent;
      width: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
    }
  }
  p{
    margin: 10px 0;
  }
  .title-md{
    font-weight: 600;
    font-size: 1.3rem;
    margin-bottom: 1rem;
  }
  .md-item{
    width: max-content;
    height: max-content;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: 1fr;
    grid-column-gap: 0.3rem;
    align-items: center;
    box-sizing: border-box;
    span{
      display: flex;
    }
  }
}

.batch {
  background: $batch_color;
}

.cloudwatch {
  //background: $cloudwatch_color;
}

.stepfun {
  //background: $stepfun_color;
}

.container-fleets-filters {
  margin-left: 1em;
  margin-top: 1em;
}

.selectedFilter {
  color: $color_selected_filter !important;
  font-weight: 700;
  fill: $fill_selected_filter;
}

.headerAll,
.headerAllFleet {
  width: 100%;
  height: 3em;
  background-color: $hover_background;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  border-bottom: solid 2px $secondary_border_color;
  display: grid;
  grid-template-columns: 260px 160px 160px 160px 180px 180px 400px 300px 1fr;
  &.header-execution{
    grid-template-columns: $execution_grid;
    background-color: #faf8f4;
  }
  &.header-weekly-refresh{
    grid-template-columns: $weekly_refresh_grid;
    background-color: #faf8f4;
  }
  &.header-client-job-size{
    grid-template-columns: $job_size_grid;
    background-color: #faf8f4;
  }
  box-sizing: border-box;
  div {
    width: 100%;
    height: 100%;
    padding: 0 1em;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-template-rows: 1fr;
    align-items: center;
    box-sizing: border-box;
    p {
      font-size: 0.8em;
      margin: 0;
      font-weight: 900;
    }
    div {
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      span {
        height: 18px;
        width: 18px;
        cursor: pointer;
        box-sizing: border-box;
        &:hover {
          fill: $header_arrow_hover;
        }
      }
    }
  }
}

.headerAllFleet {
  margin-left: 1em;
  margin-top: 1em;
  width: calc(100% - 1em);
  grid-template-columns: minmax(560px, 1fr) minmax(157px, 1fr) repeat(6, minmax(105px, 1fr)) minmax(360px, 1fr);
    &.header-task{
      grid-template-columns:180px 120px 180px 180px 100px 320px 1fr;
    }
}

.delete-filters {
  width: max-content;
  height: max-content;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 1fr;
  grid-column-gap: 0.3em;
  align-items: center;
  box-sizing: border-box;
  margin: 1em 0;
  cursor: pointer;
  &:hover {
    color: $color_login_title;
    fill: $color_login_title;
  }
  p {
    font-weight: 700;
    font-size: $font_small;
    margin: 0;
  }
}

.delete-filters-fleet {
  margin-left: 1em;
}

.filters-jobs {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: max-content max-content max-content max-content max-content;
  grid-column-gap: 1em;
  align-items: center;
  box-sizing: border-box;
}

.no-data-filter{
  padding: 10px;
}

.individual-filter {
  height: max-content;
  position: relative;
}

.status-list {
  width: 100%;
  top: 3em;
  position: absolute;
  box-sizing: border-box;
  background-color: $contrast_text_color;
  border: solid 2px $secondary_border_color;
  border-radius: 8px;
  padding: 0.5em;
  max-height: 19em;
  overflow: auto;
  p {
    margin: 0;
  }
  ul {
    width: 100%;
    height: max-content;
    box-sizing: border-box;
    li {
      height: max-content;
      display: grid;
      grid-template-columns: max-content 1fr;
      grid-column-gap: 1rem;
      box-sizing: border-box;
      padding: 0.7em 1em;
      &:hover {
        background-color: $hover_background;
      }
      p {
        font-size: $font_normal_small;
      }
    }
  }
}

.arrow-up-div {
  width: 0;
  height: 0;
  border-left: 0.7em solid transparent;
  border-right: 0.7em solid transparent;
  border-bottom: 0.7em solid $secondary_border_color;
  position: absolute;
  top: -0.7em;
  right: 1.5em;
}

.filters-select {
  margin-bottom: 1em;
  width: 12em;
  .filters-invert {
    color: #888888;
  }
}

.status-title {
  width: max-content;
  height: max-content;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 0.4em;
  align-items: center;
  box-sizing: border-box;
  text-transform: capitalize;
  p {
    font-size: $font_normal_small;
    font-weight: 700;
    margin: 0;
  }
}

.selected-status {
  background-color: $hover_background;
}

.refresh-button-filters-container {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  box-sizing: border-box;
}

.refresh-button-filters {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-template-rows: 1fr;
  align-items: center;
  border-bottom: solid 1px $secondary_border_color;
  box-sizing: border-box;
}

.refresh-button {
  width: max-content;
  display: grid;
  justify-self: flex-end;
  right: 2.6em;
  top: 3em;
  grid-template-columns: max-content max-content;
  grid-template-rows: 1fr;
  grid-column-gap: 0.2em;
  justify-content: end;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 700;
  font-size: $font_normal_small;
  color: $color_login_title;
  fill: $color_login_title;
  &:hover {
    color: $button_background;
    fill: $button_background;
  }
}

.filters {
  width: max-content;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 1fr;
  grid-column-gap: 0.4em;
  align-items: center;
  font-weight: 900;
  text-transform: uppercase;
  box-sizing: border-box;
  color: $color_text_body;
  fill: $color_text_body;
  cursor: pointer;
  box-sizing: border-box;
  &:hover {
    color: $primary_text_color;
    fill: $primary_text_color;
  }
  p {
    font-family: revert, $primary_font;
    font-weight: 500;
    font-size: 15px;
    font-weight: 800;
    box-sizing: border-box;
  }
}

.job-element {
  width: 100%;
}

.rectangle {
  height: 10px;
  width: 500px;
  background: $border_image_loader;
  background-size: 500px 100px;
  animation-name: moving-gradient;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes moving-gradient {
  0% {
    background-position: -250px 0;
  }
  100% {
    background-position: 250px 0;
  }
}

.spinner-container {
  z-index: 3;
  position: relative;
  width: max-content;
  height: max-content;
  text-align: center;
}

.spinner {
  margin: auto;
  border: 2px solid #dbf2ff;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  border-right: 2px solid #018df7;
  animation-name: spin;
  animation-duration: 900ms;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.53, 0.21, 0.29, 0.67);
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg);}
  100% { -webkit-transform: rotate(360deg); }
}

.copy-text {
  cursor: pointer;
}

.fleet-event {
  margin-bottom: 0.5em;
}
