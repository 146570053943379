@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;900&family=Ubuntu:wght@300&display=swap");

$primary_font: "Roboto", sans-serif, -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;

$secondary_font: "Ubuntu", sans-serif, source-code-pro, Menlo, Monaco, Consolas,
  "Courier New", monospace;

$font_big: 1.6em;
$font_normal: 1em;
$font_normal_small: 0.9em;
$font_small: 0.8em;
$font_very_small: 0.7em;
