@import "colors", "fonts", "Header", "LeftNav", "Modal", "Tables", 'LettersIcon';

html,
body {
  overflow: hidden;
}

main {
  width: 100%;
  height: calc(100vh - 5em);
  display: grid;
  grid-template-columns: 270px 1fr;
  grid-template-rows: 1fr;
  box-sizing: border-box;
  font-family: $secondary_font;
  overflow: hidden;
}

.smallMain {
  grid-template-columns: 70px 1fr;
}

.content {
  width: 100%;
  height: calc(100vh - 5em);
  background-color: $background_body;
  padding: 3em 2em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  grid-row-gap: 1em;
  overflow: auto;
  box-sizing: border-box;
}

.first-row {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-template-rows: 1fr;
  grid-column-gap: 20px;
  font-size: $font_small;
  align-items: center;
  box-sizing: border-box;
}

.container-icon {
  width: 35px;
  height: 35px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right, #eeb1a3, #e27c66);
  fill: $primary_background_color;
}

.table-container {
  position: relative;
  width: 100%;
  height: max-content;
  border-radius: 5px;
  padding: 2.3em;
  background-color: $primary_background_color;
  box-sizing: border-box;
  border: solid 1px $border_color;
  h1 {
    font-size: $font_normal;
    margin-bottom: 1.2em;
  }
}

.table-container-header {
  width: 100%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  p {
    font-size: 1em;
    margin-bottom: 2.2em;
  }
  div {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    fill: $selected_li;
    &:hover {
      color: $selected_li;
    }
    p {
      padding-left: 0.4em;
      margin: 0;
      font-size: $font_normal_small;
      font-weight: 800;
    }
  }
}

.grid-options-home{
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
  grid-gap: 1.5rem;
  box-sizing: border-box;
  a{
    width: 100%;
    height: 5rem;
    text-decoration: none;
    background-color: $home_buttom_background;
    border-radius: 0.5rem;
    color: $contrast_text_color;
    padding: 0 2rem;
    cursor: pointer;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: max-content 1fr;
    grid-column-gap: 1rem;
    align-items: center;
    transition: all 1s;
    box-sizing: border-box;
    p{
      font-size: 0.9rem;
      font-weight: 600;
      margin: 0;
      letter-spacing: 1px;
    }
  }
  a:hover{
    background-color: $home_button_background_hover;
  }
}