.letters-icon {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 50%;
    font-size: calc(6px + 2vmin);
    text-transform: uppercase;
    user-select: none;
    background-color: skyblue;
    color: white;
  }
  
.letters-icon.large{
    width: 45px;
    height: 45px;
}

.letters-icon.small{
    width: 38px;
    height: 38px;
}