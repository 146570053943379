@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap");

.notfound {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  h1,
  h2 {
    margin: 0;
  }
  h2 {
    margin-top: 0.3em;
    font-family: "Roboto";
  }
  p {
    width: 400px;
    font-size: 0.9em;
    color: gray;
    text-align: center;
    font-family: "Roboto";
    margin: 2em;
  }
  button {
    border: none;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: #0046d5;
    display: inline-block;
    padding: 15px 30px;
    border-radius: 40px;
    color: #fff;
    font-weight: 700;
    -webkit-box-shadow: 0px 4px 15px -5px #0046d5;
    box-shadow: 0px 4px 15px -5px #0046d5;
    cursor: pointer;
  }
}

.notfound-404 {
  font-family: "Montserrat", sans-serif;
  font-size: 6.5em;
  font-weight: 700;
  background: url("../../public/background-not-found.jpg") no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}
