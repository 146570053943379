//Text
$primary_text_color: #000; //use
$secondary_text_color: #9c9fa6; //use
$terciary_text_color: #858585; //use
$h1_primary_color: #eeb1a3; //use
$hover_color: #e27c66; //use
$modal_title_color: #ebebeb; //use
$modal_p_color: #a5a1a1; //use
$contrast_text_color: #ffffff; //use
$color_login_title: #0a5eac; //use

$error_color: #ff6347;
$color_text_button: #a35dff;
$color_text_body: #606060; //use
$color_text_selected: #eb7258; //use

//Background
$primary_background_color: #fff; //use
$secondary_background_color: #fafafa;
$background_body: #faf8f4; //use
$hover_background: #f2edf3; //use
$backgound_modal: #b1b1b166; //use

//Border
$border_color: #ebebeb; //use
$secondary_border_color: #dcdcdc; //use
$border_image_primary: linear-gradient(to right, #eeb1a3, #ff6347, #ac75ff) 1; //use
$border_image_loader: linear-gradient(
  to right,
  #eee 20%,
  #ddd 50%,
  #eee 80%
); //use

//Shadow
$shadow_color_box: #a35dff59;

//Status
$avaible_status_color: #1bcfb4; //use
$succeeded_color: #42b883; //use
$running_color: #007cb9; //use
$pending_color: #9c9fa6; //use
$failed_color: #f5310e; //use
$completed_color: #c7c72a; //use
$aborted_color: #c7c72a; //use

//Links
$airflow_color: linear-gradient(to right, #ff6347, #ff856b); //use
$batch_color: linear-gradient(to right, #047edf, #66b1ed 99%); //use
$cloudwatch_color: linear-gradient(to right, #07cdae, #53e1cb); //use
$stepfun_color: linear-gradient(to right, #6fa3a7, #58a080); //use

//SelectedFilter
$color_selected_filter: #fd2600; //use
$fill_selected_filter: #42b883; //use

//Table
$job_background_color: #faf8f4; //use
$fleet_background_color: #eaece5; //use
$run_background_color: #d0ddd0; //use
$border_hover: #dbc7fa;
$border_bottom_table: rgb(204, 197, 204); //use
$table_load_background: #fafafa; //use
$msg_table: #fbf2d5; //use
$selected_li: #e27c66;

//button_background
$button_background: #4285f4; //use
$home_buttom_background: #2073c1; //use
$home_button_background_hover: #0759a7; //use

//header_arrow
$header_arrow_hover: #047edf; //use
$clear_filter_hover: #055780; //use
