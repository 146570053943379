@import "colors", "fonts";

.modal-container {
  width: 100vw;
  height: 100vh;
  background-color: $backgound_modal;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  backdrop-filter: brightness(0.5);
  display: flex;
  justify-content: flex-end;
  section {
    width: 550px;
    max-width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
    background: $primary_background_color;
    box-sizing: border-box;
    border-left: solid 1px $secondary_border_color;
    .header {
      width: 100%;
      height: 4em;
      display: grid;
      grid-template-columns: 1fr max-content;
      grid-template-rows: 1fr;
      align-items: center;
      text-transform: uppercase;
      background-color: #f5f3ee85;
      border-bottom: solid 2px;
      border-image: $border_image_primary;
      padding: 0 2em;
      box-sizing: border-box;
      h1 {
        width: max-content;
        margin: 0;
        font-family: "Roboto";
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 0.95em;
      }
      span {
        width: max-content;
        height: max-content;
        fill: $terciary_text_color;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          fill: #000000;
        }
      }
    }
    .body-section {
      width: 100%;
      height: 100%;
      padding: 2em;
      display: grid;
      grid-template-columns: 1fr;
      grid-auto-rows: max-content;
      grid-row-gap: 1em;
      align-items: center;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.section-item {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  box-sizing: border-box;
  p {
    margin: 0;
    color: #a5a1a1;
  }
  .title {
    margin-bottom: 1em;
    font-size: 15px;
    h2 {
      margin: 0;
      font-size: 1em;
      font-family: "Roboto";
      font-weight: 500;
      padding-bottom: 0.5em;
      border-bottom: solid 1px $border_color;
    }
  }
  a {
    width: max-content;
    padding: 0.4em 1em;
    box-sizing: border-box;
    border-radius: 6px;
    text-decoration: none;
    color: #fff;
    background-color: #000;
    font-size: 0.9em;
    font-weight: 700;
    cursor: pointer;
  }
  pre {
    overflow: scroll;
  }
}

.status {
  p {
    color: $contrast_text_color;
  }
}

.section-item-double {
  width: 100%;
  height: max-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: max-content max-content;
  grid-gap: 1em 3em;
  box-sizing: border-box;
  .title {
    h3 {
      margin: 0;
      font-size: 0.9em;
      font-family: "Roboto";
      font-weight: 500;
      padding-bottom: 0.5em;
      border-bottom: solid 1px $modal_title_color;
    }
  }
  p {
    margin: 0;
    font-size: 0.9em;
    color: $modal_p_color;
  }
}

.tooltip {
  .tooltip-text {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: relative;
    z-index: 1;
    bottom: 3em;
    margin-left: 5em;
    white-space: nowrap;
    width: max-content;
  }

  div {
    position: absolute;
  }

  &:hover .tooltip-text{
    visibility: visible;
  }
}
