@import "fonts", "colors";

.app {
  width: 100vw;
  min-height: 100vh;
  font-family: $primary_font;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: absolute;
  z-index: 1;
}

.container-login {
  width: max-content;
  height: max-content;
  background-color: $primary_background_color;
  padding: 15px;
  h1 {
    color: $color_login_title;
  }
  p {
    color: $error_color;
    font-weight: 600;
  }
}

#tsparticles {
  z-index: -1;
}

#loading-spinner {
  display: inline;
  position: relative;
  z-index: 999999;
}

#loading-spinner .spin-icon {
  margin: auto;
  width: 50px;
  height: 50px;

  border: solid 3px transparent;
  border-top-color:  #666;
  border-left-color: #666;
  border-radius: 50px;

  -webkit-animation: loading-spinner 500ms linear infinite;
  -moz-animation:    loading-spinner 500ms linear infinite;
  -ms-animation:     loading-spinner 500ms linear infinite;
  -o-animation:      loading-spinner 500ms linear infinite;
  animation:         loading-spinner 500ms linear infinite;
}

@-webkit-keyframes loading-spinner {
  0%   { -webkit-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@-moz-keyframes loading-spinner {
  0%   { -moz-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -moz-transform: rotate(360deg); transform: rotate(360deg); }
}

@-o-keyframes loading-spinner {
  0%   { -o-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -o-transform: rotate(360deg); transform: rotate(360deg); }
}

@-ms-keyframes loading-spinner {
  0%   { -ms-transform: rotate(0deg);   transform: rotate(0deg); }
  100% { -ms-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes loading-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}

#credential_picker_container{
  display: none;
}
